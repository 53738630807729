@import 'react-querybuilder/dist/query-builder.scss';

.ruleGroup {
  @apply relative flex flex-col;
}

.ruleGroup[data-level='0'] {
  @apply bg-transparent border-none;
}

.ruleGroup:not([data-level='0']) {
  @apply bg-transparent rounded-md border border-gray-300 p-0;
}

.ruleGroup:not([data-level='0']):not([data-path='[0]']) {
  @apply mt-20;
}

.ruleGroup:not([data-level='0']):not([data-path='[0]'])::after {
  @apply absolute -top-[3.25rem] left-0 font-bold content-['Or_when'];
}

.rule {
  @apply relative flex p-6 space-x-4;
}

.rule:not(:first-child) {
  @apply border-t border-gray-300;
}

.ruleGroup {
  @apply gap-0;
}

.ruleGroup-combinators,
.ruleGroup-remove {
  @apply hidden;
}

.ruleGroup-header {
  @apply order-1;
}

.ruleGroup-body {
  @apply order-[0] !gap-0;
}

/* DEMO: Invalid values based on configured validators */
.queryBuilder-invalid > input {
  @apply border border-red-500 rounded-md;
}

.cel-tester {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.cel-tester h4 {
  margin-top: 0;
  color: #333;
}

.cel-tester h5 {
  margin-bottom: 10px;
  color: #555;
}

.test-data-form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 5px;
}

.attachment-input {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.attachment-input input {
  flex: 1;
  margin-right: 5px;
  margin-bottom: 0;
}

.attachment-input button {
  width: 30px;
  height: 30px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.attachment-input button:hover {
  background-color: #e0e0e0;
}

.attachment-input button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cel-expression {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.cel-tester button {
  padding: 8px 16px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.cel-tester button:hover {
  background-color: #3367d6;
}

.cel-tester button:disabled {
  background-color: #a4c1f4;
  cursor: not-allowed;
}

.error-message {
  margin-top: 15px;
  padding: 10px;
  background-color: #ffebee;
  border-left: 4px solid #f44336;
  color: #b71c1c;
  border-radius: 4px;
}

.evaluation-result {
  margin-top: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.result {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
}

.result.success {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #c8e6c9;
}

.result.failure {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ffcdd2;
}

.cors-note {
  margin-top: 20px;
  padding: 15px;
  background-color: #fff8e1;
  border-left: 4px solid #ffc107;
  border-radius: 4px;
}

.cors-note ol {
  margin-top: 5px;
  margin-bottom: 0;
  padding-left: 20px;
}
